<template>
    <div class="bg-white w-full">
        <AlertBanner :page="basicPage" :slug="basicSlug"/>
    </div>
</template>

<script>
import AlertBanner from '../../../views/components/shop/AlertBanners.vue';


export default {
    components: {
        AlertBanner
    },
    data: () => ({
        basicSlug: "",
        basicPage: ""
    }),
    methods: {
        getPage(pageName) {
            switch (pageName) {
                case "BasicOrderCenter":
                    this.basicPage = "homepage"
                    break;
                case "BasicOrderCenterCategoryPage":
                    this.basicPage = "category"
                    break;
                // case "NurseOrderCenterCategory_Page":
                //     this.basicPage = "category"
                //     break;
                case "BasicOrderCenterBrandPage":
                    this.basicPage = "brand"
                    break;
                case "BasicProductDetail":
                    this.basicPage = "productDetail"
                    break;
                case "BasicProductCheckout":
                    this.basicPage = "checkout"
                    break;
                case "BasicOrderCenterPromotionsPage":
                    this.basicPage = "promotions"
                    break;
                case "BasicOrderCenterFreshLifePage":
                    this.basicPage = "fresh-life"
                    break;
                default:
                    break;
            }
        }
    },
    watch: {
        "$route.name": {
            handler(newVal, oldVal) {
                if(newVal !== oldVal){
                    this.getPage(this.$route.name);
                }
            },
            immediate: true
        },
        "$route.params.slug": {
            handler(newVal, oldVal) {
                if(newVal !== oldVal){
                    this.basicSlug = newVal 
                }
            }
        }
    },
    created() {
        this.basicSlug = this.$route.params.slug
        this.getPage(this.$route.name);
    }
}

</script>
