export default [
  ...process.env.VUE_APP_REGION === "US" ? [
    {
      url: "/basic/notice-board",
      name: "Dashboard",
      icon: "MonitorIcon",
      slug: "dashboard",
      i18n: "Notice Board"
    },
  ] : [
    {
      url: "/basic/dashboard",
      name: "Dashboard",
      icon: "MonitorIcon",
      slug: "dashboard",
      i18n: "Dashboard"
    },
  ],
  {
    url: "/basic/events",
    name: "Events",
    icon: "CalendarIcon",
    slug: "events",
    i18n: "Events"
  },
  {
    url: "/basic/resources",
    name: "Resources",
    icon: "FolderIcon",
    slug: "resources",
    i18n: "Resources"
  },
  ...process.env.VUE_APP_REGION === "US" ? [
    {
      url: '/basic/pos',
      name: "POS",
      slug: "pos",
      i18n: "POS",
      icon: "DollarSignIcon",
    },
  ] : [],
];
